import React, { useState, useEffect } from "react";
import Classes from "./MainHead.module.css";
import Logo from "../../Assets/mirrorLogo.png";
import swaMob from "../../Assets/swaMob.png";
import { useHistory } from "react-router-dom";

const MainHead = (props) => {
  const history = useHistory();

  const landingPageHandler = () => {
    history.push("/");
  };

  return (
    <div>
      <header>
        <div className="container nopadmar">
          <div className={Classes.SwaHead}>
            <div
              id="brand"
              className={Classes.logo}
              onClick={landingPageHandler}
            >
              <img src={Logo} alt="logo" style={{ maxWidth: "150px" }} />
            </div>
            <div
              id="brand"
              className={Classes.swaMob}
              onClick={landingPageHandler}
            >
              <img src={swaMob} alt="logo" />
            </div>
            {props.children}
          </div>
        </div>
      </header>
    </div>
  );
};

export default MainHead;
